<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;">证书简介列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">证书名称:</span>
              <el-input
                v-model="certName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入证书名称"
                clearable
              />
            </div>
            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()">查询</el-button>
              <el-button  class="bgc-bv" type="primary" @click="EditDialog('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="证书名称"
                align="center"
                prop="certName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="标签"
                align="center"
                prop="certLabel"
                :show-overflow-tooltip="true"
              />
              <el-table-column label="封页" align="center" prop="certCoverUrl">
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px; border-radius: 50%"
                    :src="scope.row.certCoverUrl"
                    :preview-src-list="[scope.row.certCoverUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="简介" align="center" prop="certContent" show-overflow-tooltip>
                <template slot-scope="scope">
                  <p v-html="scope.row.certContent"></p>
                </template>
              </el-table-column>
              <el-table-column
                label="内容图片"
                align="center"
                prop="certPhotoUrl"
              >
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px; border-radius: 50%"
                    :src="scope.row.certPhotoUrl"
                    :preview-src-list="[scope.row.certPhotoUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <!-- <i class="el-icon-folder-delete"></i> -->
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="certState">
                <template slot-scope="scope">
                  {{ scope.row.certState == "10" ? "启用" : "停用" }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="center"
                prop="createTime"
              />
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="EditDialog('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="scope.row.certState == '10'"
                    @click="certificateStart(scope.row)"
                    >启用</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                     :disabled="scope.row.certState == '20'"
                    @click="certificateStop(scope.row)"
                    >停用</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      :title="DialogTitle"
      :visible.sync="DialogVisible"
      width="50%"
      center
      @close="DialogCancel"
    >
      <el-form
        ref="DialogFromRef"
        :model="DialogFrom"
        :rules="DialogFromRules"
        label-width="120px"
      >
        <el-form-item label="证书名称" prop="certName" size>
          <el-input
            v-model="DialogFrom.certName"
          ></el-input>
        </el-form-item>
        <el-form-item label="证书标签" prop="certLabel">
          <el-input
            v-model="DialogFrom.certLabel"
          ></el-input>
          <p>多个标签请逗号分隔</p>
        </el-form-item>
        <el-form-item label="简介封面" prop="certCoverUrl">
          <el-upload
            :on-change="handlebusinessLicense"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="DialogFrom.certCoverUrl || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="简介"
          prop="certContent"
          class="form-item"
          required
        >
          <div ref="editor" class="editor" style="width: 100%" />
        </el-form-item>
        <el-form-item label="内容图片" prop="certPhoto">
          <el-upload
            :on-change="handlebusinessLicense1"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="DialogFrom.certPhotoUrl || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogCancel">取 消</el-button>
        <el-button type="primary" @click="DialogSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
let editor = {};
export default {
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data: function () {
    return {
      certName: "",
      DialogTitle: "新增",
      DialogVisible: false,
      DialogFrom: {
        certName: "", //证书名称
        certLabel: "", //证书标签
        certCover: "", //简介封面
        certCoverUrl: "", //简介封面
        certContent: "", //简介
        certPhoto: "", //内容图片
        certPhotoUrl: "", //内容图片
      },
      DialogFromRules: {
        certName: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certLabel: [
          { required: true, message: "请输入证书标签", trigger: "blur" },
        ],
        certCoverUrl: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        certPhotoUrl: [
          { required: true, message: "请上传内容图片", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    // this.editorInit();
  },
  methods: {
    /* 简介封面图 */
    handlebusinessLicense(res) {
      this.uploadFetch(res.raw);
    },
    handlebusinessLicense1(res) {
      this.uploadFetch1(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certCoverUrl = ret.data.fileURL || "";
        that.DialogFrom.certCover = ret.data.fileKey || "";
      });
    },
    uploadFetch1(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certPhoto = ret.data.fileKey || "";
        that.DialogFrom.certPhotoUrl = ret.data.fileURL || "";
      });
    },
    // 富文本编辑器
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.DialogFrom.certContent = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        certName: this.certName,
      };
      this.doFetch(
        {
          url: "/mini/certinfo/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 新增||编辑 */
    EditDialog(stu, row) {
      this.DialogVisible = true;
      this.$nextTick(function () {
        this.editorInit();
      });
      if (stu == "edit") {
        this.DialogTitle = "编辑证书";
        this.getInfo(row.introId);
      } else {
          this.DialogTitle = "新增证书";
      }
    },
    getInfo(introId) {
      this.$post(
        "/mini/certinfo/getInfo",
        {
          introId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.DialogFrom = {
            ...this.DialogFrom,
            ...ret.data,
          };
          editor.txt.html(ret.data.certContent);
        })
        .catch((err) => {
          return;
        });
    },
    //新增确认
    DialogSure() {
      this.$refs.DialogFromRef.validate((valid) => {
        if (valid) {
          let data = {
            certName: this.DialogFrom.certName, // 证书名称
            certLabel: this.DialogFrom.certLabel, // 证书标签
            certCover: this.DialogFrom.certCover, // 封面
            certContent: this.DialogFrom.certContent, // 简介
            certPhoto: this.DialogFrom.certPhoto, // 内容图片
          };

          if (this.DialogFrom.introId) {
            data.introId = this.DialogFrom.introId;
          }
          this.$post(
            this.DialogFrom.introId
              ? "/mini/certinfo/modify"
              : "/mini/certinfo/insert",
            data,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message.success("保存成功");
                this.empty();
                this.DialogVisible = false;
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    //新增取消
    DialogCancel() {
      this.empty();
      this.DialogVisible = false;
    },
    empty() {
      this.DialogFrom = {
        certName: "", //证书名称
        certLabel: "", //证书标签
        certCover: "", //简介封面
        certCoverUrl: "", //简介封面
        certContent: "", //简介
        certPhoto: "", //内容图片
        certPhotoUrl: "", //内容图片
      };
         editor.txt.clear();
      this.$refs.DialogFromRef.resetFields();
    },
    /* 启用  */
    certificateStart(row) {
        this.$post('/mini/certinfo/state',{introId:row.introId,certState:'10'},3000,true,2).then(ret => {
            if (ret.status == '0') {
                this.$message.success(ret.message)
                this.getData(-1)
            }
        })
    },
    /* 停用 */
    certificateStop(row) {
         this.$post('/mini/certinfo/state',{introId:row.introId,certState:'20'},3000,true,2).then(ret => {
            if (ret.status == '0') {
                this.$message.success(ret.message)
                 this.getData(-1)
            }
        })
    },
  },
};
</script>

<style scope>
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>